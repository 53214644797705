import React from "react";
import { Box, Container, Image, Heading, Text } from "theme-ui";
import { keyframes } from "@emotion/core";
import contactIcon1 from "assets/contact-icon-1.svg";
import contactIcon2 from "assets/contact-icon-2.svg";
import { colors } from "../style_dependencies/variables";

const ContactBanner = () => {
  return (
    <Box as="section" sx={styles.banner}>
      <Container sx={styles.container}>
        <Image
          sx={styles.contactIcon1}
          className="bannerIcon"
          alt="banner icon"
          src={contactIcon1}
        />
        <Image
          sx={styles.contactIcon2}
          className="bannerIcon"
          alt="banner icon"
          src={contactIcon2}
        />
        <Heading as="h1" sx={styles.container.heading}>
          Contact Us
        </Heading>
        <Text as="p" sx={styles.container.text}>
          We'd love to hear from you! Our team is available to chat.
        </Text>{" "}
      </Container>
    </Box>
  );
};

export default ContactBanner;

const bannerAnim = keyframes`
    0% {
        transform: translateY(0px) translateX(0) rotate(0);
    }

    30% {
        transform: translateY(30px) translateX(30px) rotate(15deg);
        transform-origin: center center;
    }

    50% {
        transform: translateY(50px) translateX(50px) rotate(45deg);
        transform-origin: right bottom;
    }

    80% {
        transform: translateY(30px) translateX(30px) rotate(15deg);
        transform-origin: left top;
    }

    100% {
        transform: translateY(0px) translateX(0) rotate(0);
        transform-origin: center center;
    }
`;

const styles = {
  banner: {
    overflow: "hidden",
    backgroundColor: colors.mainColor,
    textAlign: "center",
    pt: ["140px", null, null, null, "150px"],
    pb: ["70px", null, null, null, "130px"],
  },
  container: {
    position: "relative",
    ".bannerIcon": {
      position: "absolute",
      display: ["none", null, null, null, "block"],
    },
    heading: {
      fontSize: ["2.5rem", null, "3rem"],
      lineHeight: 1.25,
      color: "#02073E",
      fontWeight: 700,
      width: "100%",
      maxWidth: ["100%", null, null, "600px", "740px", "851px"],
      mx: "auto",
      mt: ["10px", null, null, "30px"],
      mb: ["20px", null, null, "65px"],
    },
    text: {
      fontSize: "18px",
    },
  },
  contactIcon1: {
    top: "10%",
    left: "5%",
    animation: `${bannerAnim} 8s linear infinite`,
  },
  contactIcon2: {
    top: "10%",
    right: "5%",
    animation: `${bannerAnim} 8s linear infinite`,
  },
};
