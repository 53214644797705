import React from "react";
import { StickyProvider } from "contexts/app/app.provider";
import SEO from "components/seo";
import Layout from "components/layout";
import ContactBanner from "sections/contact-banner";
import ContactFormSection from "sections/contact-form-section";
import ContactCards from "components/contact-cards";

const seoData = {
  title: "Contact Us | Fapshi",
  description:
    "Contact Fapshi. Find email and contact information for Fapshi, including help and support resources. Submit your request directly via email by filling in the form.",
  keywords:
    "contact fapshi, fapshi contact, fapshi contact email, fapshi contact details, fapshi resources, startups in buea, fapshi developers",
  pathname: "contact",
};

export default function Contact() {
  return (
    <StickyProvider>
      <Layout>
        <SEO data={seoData} />
        <ContactBanner />
        <ContactFormSection />
        <ContactCards />
      </Layout>
    </StickyProvider>
  );
}
