/** @jsx jsx */
import { jsx, Text, Heading, Container, Button, Box } from "theme-ui";
import Grid from "@mui/material/Grid";
import { colors } from "../style_dependencies/variables";
import React, { useState } from "react";
import CallOutlinedIcon from "@mui/icons-material/CallOutlined";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import CircularProgress from "@mui/material/CircularProgress";

import Avatar from "@mui/material/Avatar";
import Input from "@mui/material/Input";

import {
  FaYoutube,
  FaFacebookF,
  FaTwitter,
  FaInstagram,
  FaLinkedinIn,
} from "react-icons/fa";

import { useFormik } from "formik";
import * as yup from "yup";
import axios from "axios";

const socialMedia = [
  {
    path: "https://www.linkedin.com/company/fapshi-pay/",
    icon: <FaLinkedinIn />,
  },
  {
    path: "https://www.youtube.com/@fapshi",
    icon: <FaYoutube />,
  },
  {
    path: "https://www.facebook.com/fapshi/",
    icon: <FaFacebookF />,
  },
  {
    path: "https://twitter.com/fapshi",
    icon: <FaTwitter />,
  },
  {
    path: "https://www.instagram.com/fapshihq/",
    icon: <FaInstagram />,
  },
];

const baseUrl = "https://api.fapshi.com/";
const route = "usercontact/contact";
const GATEWAY_URL = baseUrl + route;

const validationSchema = yup.object({
  name: yup
    .string()
    .min(2, "Please enter at least your first name")
    .required("This field is required!"),
  phone: yup
    .number()
    .positive("Your phone number can only contain positive integers")
    .integer("Your phone number can only contain positive integers")
    .notRequired(),
  email: yup
    .string()
    .email("Please enter a valid email address")
    .required("This field is required!"),
  subject: yup
    .string()
    .min(5, "Your subject is too short")
    .max(100, "Your subject is too long")
    .required("This field is required!"),
  message: yup
    .string()
    .min(10, "Your message cannot be this short")
    .required("This field is required!"),
});

const ContactFormSection = () => {
  const [success, setSuccess] = useState("");
  const [error, setError] = useState("");
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onSubmit = async (values) => {
    const { name, phone, email, subject, message } = values;
    const data = {
      username: name,
      phone,
      email,
      subject,
      description: message,
      type: "Contact",
    };
    setLoading(true);

    const response = await axios.post(GATEWAY_URL, data).catch((err) => {
      if (err && err.response) {
        setLoading(false);
        setSuccess("");
        setError(
          "Oops! Looks like there was an issue – we couldn't send your message. Please check that you have filled the form correctly, or that your internet connection is okay and try again."
        );
        handleClickOpen();
      }
    });
    if (response && response.data) {
      setLoading(false);
      setError("");
      setSuccess(
        "Thanks for contacting Fapshi! Your message has been sent successfully. A member of our team will get back to you."
      );
      handleClickOpen();
      formik.resetForm();
    }
  };

  const initVals = {
    name: "",
    phone: "",
    email: "",
    subject: "",
    message: "",
  };

  const formik = useFormik({
    initialValues: initVals,
    validateOnBlur: true,
    onSubmit,
    validationSchema,
  });

  const showForm = (
    <>
      <Box sx={styles.popup}>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          sx={success ? styles.success : styles.error}
        >
          <DialogTitle>
            {success && (
              <Box sx={styles.popup.successBox}>
                <CheckCircleOutlineIcon />
                <Heading>Success</Heading>
              </Box>
            )}
            {error && (
              <Box sx={styles.popup.errorBox}>
                <HighlightOffIcon />
                <Heading>Error</Heading>
              </Box>
            )}
          </DialogTitle>
          <DialogContent>
            <DialogContentText sx={styles.popup.text}>
              {success || error}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} sx={styles.popup.btn}>
              CLOSE
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
      <form onSubmit={formik.handleSubmit} sx={styles.form}>
        {loading && <CircularProgress sx={styles.progress} />}
        <label htmlFor="name">
          <h5 sx={styles.form.label}>Name</h5>
          <Input
            id="name"
            type="text"
            name="name"
            value={formik.values.name}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            placeholder="Enter your name"
            disabled={formik.isSubmitting}
            sx={styles.form.input}
          />
          {formik.touched.name && formik.errors.name && (
            <Text sx={styles.form.fieldError}>{formik.errors.name}</Text>
          )}
        </label>

        <label htmlFor="phone">
          <h5 sx={styles.form.label}>Phone Number</h5>
          <Input
            id="phone"
            type="number"
            phone="phone"
            value={formik.values.phone}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            placeholder="Enter your phone number"
            disabled={formik.isSubmitting}
            sx={styles.form.input}
          />
          {formik.touched.phone && formik.errors.phone && (
            <Text sx={styles.form.fieldError}>{formik.errors.phone}</Text>
          )}
        </label>

        <label htmlFor="email">
          <h5 sx={styles.form.label}>Email</h5>
          <Input
            id="email"
            type="email"
            name="email"
            value={formik.values.email}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            placeholder="your@email.address"
            disabled={formik.isSubmitting}
            sx={styles.form.input}
          />
          {formik.touched.email && formik.errors.email && (
            <Text sx={styles.form.fieldError}>{formik.errors.email}</Text>
          )}
        </label>

        <label htmlFor="subject">
          <h5 sx={styles.form.label}>Subject</h5>
          <Input
            id="subject"
            type="text"
            name="subject"
            placeholder="Enter a headline for your message"
            value={formik.values.subject}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            disabled={formik.isSubmitting}
            sx={styles.form.input}
          />
          {formik.touched.subject && formik.errors.subject && (
            <Text sx={styles.form.fieldError}>{formik.errors.subject}</Text>
          )}
        </label>

        <label htmlFor="message">
          <h5 sx={styles.form.label}>Message</h5>
          <Input
            id="message"
            type="text"
            name="message"
            value={formik.values.message}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            multiline
            rows={4}
            placeholder="Enter your message"
            disabled={formik.isSubmitting}
            sx={styles.form.input}
          />
          {formik.touched.message && formik.errors.message && (
            <Text sx={styles.form.fieldError}>{formik.errors.message}</Text>
          )}
        </label>

        <Box sx={{ display: "flex", justifyContent: "end" }}>
          <Button
            sx={styles.form.btn}
            type="submit"
            disabled={!formik.isValid || formik.isSubmitting}
          >
            Send Message
          </Button>
        </Box>
      </form>
    </>
  );

  return (
    <Container>
      <Grid container spacing={2} sx={styles.gridContainer}>
        <Grid item xs={12} sm={6} lg={4} sx={styles.contact}>
          <Box>
            <Heading as="h2">Contact Details</Heading>
            <Text as="p">
              Fill in the form and our team will get back to you quickly.
            </Text>
          </Box>
          <List sx={styles.contact.list}>
            <ListItem>
              <ListItemAvatar sx={styles.contact.listItem}>
                <Avatar sx={styles.avatar}>
                  <CallOutlinedIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                sx={styles.contact.text}
                primary="+237 673 669 111"
                secondary="Tue - Sat, 9am to 5pm"
              />
            </ListItem>
            <ListItem>
              <ListItemAvatar sx={styles.contact.listItem}>
                <Avatar sx={styles.avatar}>
                  <EmailOutlinedIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                sx={styles.contact.text}
                primary="hello@fapshi.com"
                secondary="Chat with us"
              />
            </ListItem>
            <ListItem>
              <ListItemAvatar sx={styles.contact.listItem}>
                <Avatar sx={styles.avatar}>
                  <LocationOnOutlinedIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                sx={styles.contact.text}
                primary="Behind Banana School - Central Market
              Road, Buea"
              />
            </ListItem>
          </List>
          <Box sx={styles.contact.socialIcons}>
            {socialMedia.map(({ path, icon }, i) => (
              <Box as="span" key={i}>
                <a href={path}>{icon}</a>
              </Box>
            ))}
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} lg={8} sx={styles.formSide}>
          {showForm}
        </Grid>
      </Grid>
    </Container>
  );
};

const styles = {
  gridContainer: {
    my: "2rem !important",
    ml: "-8px !important",
    p: "0.5rem",
    borderRadius: "8px",
    boxShadow: "rgba(100, 100, 111, 0.1) 0px 7px 29px 0px",

    "@media screen and (max-width: 600px)": {
      pt: "0.5rem !important",
      pb: "1rem !important",
    },
  },
  contact: {
    display: "flex",
    flexDirection: "column !important",
    justifyContent: "space-between",
    px: "2rem !important",
    borderRadius: "8px",
    backgroundColor: colors.btnColor,
    color: colors.card2Btn,

    "@media screen and (max-width: 312px)": {
      px: "1rem !important",
    },

    h2: {
      fontWeight: 600,
      fontSize: "2rem",
      color: colors.secondary,
      mb: 2,
      opacity: 1,
    },
    p: {
      opacity: 0.9,
      fontWeight: 400,
    },
    listItem: {
      minWidth: "50px",
    },
    list: {
      my: "1rem",
      li: {
        pl: 0,
      },
    },
    text: {
      "span, p": {
        fontFamily: "'Hind', sans-serif",
        fontWeight: 400,
        opacity: 0.95,
        color: colors.card2Btn,
      },
      span: {
        opacity: 1,
      },
    },
    socialIcons: {
      display: "flex",
      alignItems: "center",
      my: "10%",

      a: {
        color: colors.btnColor,
        backgroundColor: colors.secondary,
        borderRadius: "50%",
        width: "30px",
        height: "30px",
        p: "7px 8px 0 8px",
        mr: 2,
      },
    },
  },
  avatar: {
    backgroundColor: colors.secondary + " !important",
    width: "30px",
    height: "30px",

    svg: {
      color: colors.btnColor,
      width: "18px",
      height: "18px",
    },
  },
  formSide: {
    px: "2rem !important",

    "@media screen and (max-width: 600px)": {
      px: "0.5rem !important",
    },
  },
  form: {
    lineHeight: 1,
    position: "relative",
    input: {
      fontFamily: "'Hind', sans-serif !important",
      fontWeight: 400,
      width: "100%",
      border: "none",
      borderRadius: "20px",
      padding: "2px",
      boxShadow: colors.mainColor + " 0px 1px 5px 0px",
      color: "unset",

      "&&&:before": {
        borderBottom: "none !important",
      },
      "&&:after": {
        borderBottom: "none !important",
      },

      "input, textarea": {
        py: "0.5rem !important",
        px: "1rem !important",
        boxShadow: "unset",
      },
    },

    label: {
      mb: "0.5rem",
    },

    fieldError: {
      color: colors.error,
      fontSize: "14px",
      p: "0.5rem 1rem",
    },

    btn: {
      backgroundColor: colors.btnColor,
      color: "white",
      my: "1.5rem",
      px: "2rem !important",
      fontSize: "17px",

      "&:disabled": {
        filter: "contrast(0.5)",
      },
    },
  },
  success: {
    ".css-1t1j96h-MuiPaper-root-MuiDialog-paper, .css-uhb5lp": {
      borderTop: "8px solid " + colors.success,
      maxWidth: "500px",
      borderRadius: "8px",
    },
  },
  error: {
    ".css-1t1j96h-MuiPaper-root-MuiDialog-paper, .css-uhb5lp": {
      borderTop: "8px solid " + colors.error,
      maxWidth: "500px",
      borderRadius: "8px",
    },
  },
  popup: {
    successBox: {
      display: "flex",
      justifyContent: "center",
      svg: {
        color: colors.success,
      },
      h2: {
        fontSize: "24px",
        fontWeight: 600,
        ml: "4px",
        mt: "-4px",
        color: "text",
      },
    },
    errorBox: {
      display: "flex",
      justifyContent: "center",
      svg: {
        color: colors.error,
      },
      h2: {
        fontSize: "24px",
        fontWeight: 600,
        ml: "4px",
        mt: "-4px",
        color: "text",
      },
    },
    text: {
      fontFamily: "'Hind', sans-serif !important",
      textAlign: "center",
    },
    btn: {
      color: "text",
      backgroundColor: "unset",
    },
  },
  progress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    color: colors.secondary,
    zIndex: 10,
  },
};

export default ContactFormSection;
